<template>
    <div class="auth-wrapper auth-v2">
        <div class="auth-inner">
            <!-- brand logo -->
            <router-link class="brand-logo d-flex align-center" to="/">
                <v-img :src="appLogo" alt="logo" class="me-3 " contain max-height="30px" max-width="30px"></v-img>
                <h2 class="text--primary"> {{ appName }} </h2></router-link>
            <!--/ brand logo -->
            <v-row class="auth-row ma-0">
                <v-col class="d-none d-lg-block position-relative overflow-hidden pa-0" lg="8">
                    <div class="auth-illustrator-wrapper">
                        <!-- triangle bg -->
                        <img :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)" class="auth-mask-bg" height="362"/>
                        <!-- tree -->
                        <v-img class="auth-tree" height="226" src="@/assets/images/misc/tree-4.png" width="300"></v-img>
                        <!-- 3d character -->
                        <div class="d-flex align-center h-full pa-16 pe-0">
                            <v-img :src="require(`@/assets/images/3d-characters/illustration-forgot-password-${$vuetify.theme.dark ? 'dark':'light'}.png`)" class="auth-3d-group" contain height="692" max-width="100%"></v-img>
                        </div>
                    </div>
                </v-col>
                <v-col class="d-flex align-center auth-bg pa-10 pb-0" lg="4">
                    <v-row>
                        <v-col class="mx-auto" cols="12" lg="12" md="6" sm="8">
                            <v-card flat>
                                <v-card-text><p class="text-2xl font-weight-semibold text--primary mb-2"> Forgot Password? 🔒 </p>
                                    <p class="mb-2"> Enter your email and we'll send you instructions to reset your password </p></v-card-text>
                                <!-- login form -->
                                <v-card-text>
                                    <v-form>
                                        <v-text-field v-model="email" class="mb-4" hide-details label="Email" outlined placeholder="john@example.com"></v-text-field>
                                        <v-btn block color="primary"> Send reset link</v-btn>
                                    </v-form>
                                </v-card-text>
                                <v-card-actions class="d-flex justify-center align-center">
                                    <router-link :to="{name:'auth-login'}" class="d-flex align-center text-sm">
                                        <v-icon color="primary" size="24"> {{ icons.mdiChevronLeft }}</v-icon>
                                        <span>Back to login</span></router-link>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script> import {mdiChevronLeft} from '@mdi/js'
import {ref} from '@vue/composition-api'
import themeConfig from '@themeConfig'

export default {
    setup() {
        const isPasswordVisible = ref(false)
        const email = ref('')
        return {
            isPasswordVisible, email,
// themeConfig
            appName: themeConfig.app.name, appLogo: themeConfig.app.logo, icons: {mdiChevronLeft,},
        }
    },
} </script>
<style lang="scss"> @import '@core/preset/preset/pages/auth.scss'; </style>
